<template>
    <div>
        <b-form>
            <b-row >
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Código Proyecto" label-for="basicInput">                        
                        <b-form-input v-model.number = "projectCode" placeholder="Digite el Código de Proyecto"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Nombre Productor" label-for="basicInput">                        
                        <b-form-input v-model = "producerName" placeholder="Digite el Nombre de Productor"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Proyecto" label-for="basicInput">                        
                        <b-form-input v-model = "projectName" placeholder="Digite el Nombre de Proyecto"></b-form-input>
                    </b-form-group>                    
                </b-col>
                <b-col md="1" xl="1" class="mb-1">
                    <b-form-group label="Departamento" label-for="basicInput">                        
                        <b-form-select @change="onDepartamentoSelect" :options="departamentosList" 
                        value-field="departamento" text-field="departamento"/>
                    </b-form-group>
                </b-col>
                <b-col md="1" xl="1" class="mb-1">
                    <b-form-group label="Municipio/Ciudad" label-for="basicInput">                        
                        <b-form-select @change="onMunicipioSelect" :options="municipiosList" 
                        value-field="municipio" text-field="municipio" />
                    </b-form-group>
                </b-col>
                <b-col md="1" xl="1" class="mb-1">
                    <b-form-group label="Fase" label-for="basicInput">                        
                        <b-form-select @change="onFaseSelect" :options="fasesList" 
                        value-field="projectStatus" text-field="projectStatus" />
                    </b-form-group>
                </b-col>
                <b-col md="2" xl="2" class="mt-2">
                    <b-button  variant="primary" @click="ProjectsToApprove" >
                        <feather-icon icon="SearchIcon" class="mr-50"/>Filtrar
                    </b-button>                        
                </b-col>                                      
            </b-row>
        </b-form>        

        <b-card-code title="Proyectos Pendientes por aprobar" no-body>
            <b-table
            ref="selectableTable"
            :fields="fields"
            :items="projects" 
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected"            
            >
                <template #cell(projectName)="data">
                    {{data.item.projectCode}} - {{(data.item.projectName)}} 
                </template>
                <template #cell(investmentRequired)="data">
                    {{parseFloat(data.item.investmentRequired).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                </template>
                <template #cell(projectProgress)="data">
                    <b-card-text class="mb-0">
                        {{parseFloat(data.item.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} de 
                        {{parseFloat(data.item.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg 
                        ({{ parseFloat(data.item.projectProgress).toFixed(2)+'%' }})
                    </b-card-text>
                    <b-progress :value="data.item.projectProgress" max="100" height="10px" variant="success" striped/>
                </template>              

            </b-table>
        </b-card-code>

        <b-row v-if="projects.length <= 0" >
            <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>No Hay Proyectos para mostrar</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-modal centered ref="my-modal" hide-footer title="¿Que deseas hacer?">
            <b-button class="mt-1" variant="primary" block @click="viewProjectPublicDetails">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Ver Proyecto</span>
            </b-button>
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="outline-primary" block @click="editProject">
                <feather-icon icon="SettingsIcon" class="mr-50"/>
                <span class="align-middle">Editar Proyecto</span>
            </b-button> 
            <b-button class="mt-2" variant="warning" block @click="followUpProject">
                <feather-icon icon="ClipboardIcon" class="mr-50"/>
                <span class="align-middle">Seguimiento Proyecto</span>
            </b-button>  
            <b-button class="mt-2" variant="primary" block @click="viewProducerDetails">
                <feather-icon icon="BookIcon" class="mr-50"/>
                <span class="align-middle">Detalles Productor</span>
            </b-button>
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="secondary" block @click="showChangeStatusProjectModal">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Cambiar Estado del Proyecto</span>
            </b-button>
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="info" block @click="getInvestmentsInvestorsDetails" >
                <feather-icon icon="DownloadIcon" class="mr-50"/>
                <span class="align-middle">Detalle Inversiones/Inversionistas</span>
            </b-button>
            <b-button v-if="(this.selectedProject.isBlockedForInvestment == false || this.selectedProject.isBlockedForInvestment == null) && this.userType == 'Admin'" class="mt-2" variant="danger" block @click="blockInvestment" >
                <feather-icon icon="LockIcon" class="mr-50"/>
                <span class="align-middle">Bloquear</span>
            </b-button>
            <b-button v-else-if="this.selectedProject.isBlockedForInvestment == true && this.userType == 'Admin'" class="mt-2" variant="danger" block @click="blockInvestment" >
                <feather-icon icon="UnlockIcon" class="mr-50"/>
                <span class="align-middle">Desbloquear</span>
            </b-button>
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="dark" block @click="goToProjectsLiquidations" >                
                <feather-icon icon="DollarSignIcon" class="mr-50"/>
                <span class="align-middle">Liquidaciones del Proyecto</span>
            </b-button>
            <b-button v-if="this.selectedProject.projectStatus == 'Ejecucion' && this.userType == 'Admin' && this.selectedProject.invoicesCreated == null" class="mt-2" variant="success" block @click="generateInvoicesByProject" >
                <feather-icon icon="UploadCloudIcon" class="mr-50"/>
                <span class="align-middle">Facturar Proyecto</span>
            </b-button>
        </b-modal>
        <b-modal centered ref="change-status-project-modal" hide-footer title="Cambiar Estado de Proyecto">
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="danger" block @click="moveToPending" >
                <feather-icon icon="AlertTriangleIcon" class="mr-50"/>
                <span class="align-middle">Pasar a Pendiente</span>
            </b-button>
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="warning" block @click="moveToCollect" >
                <feather-icon icon="ClockIcon" class="mr-50"/>
                <span class="align-middle">Pasar a Recaudo</span>
            </b-button>            
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="primary" block @click="moveToExecuting" >
                <feather-icon icon="CheckCircleIcon" class="mr-50"/>
                <span class="align-middle">Pasar a Ejecución</span>
            </b-button>                        
            <b-button v-if="this.userType == 'Admin'" class="mt-2" variant="info" block @click="moveToFinished" >
                <feather-icon icon="FlagIcon" class="mr-50"/>
                <span class="align-middle">Finalizar Proyecto</span>
            </b-button>
        </b-modal>
    </div>
</template>


<script>
import axios from '@axios'
import axiosIns from '../../libs/axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import { BAlert, BTable, BForm, BFormInput, BProgress, BFormGroup, BFormSelect, BButton, 
BRow, BCol, BCardText} from 'bootstrap-vue'
import departamentos from '../../assets/departamentos-municipios.json'
import Ripple from 'vue-ripple-directive'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
    components: {
        BCardCode,
        BCardText,
        BTable,
        BForm,
        BProgress,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormSelect,
        BAlert,
    },
    data(){
        return{
            selectMode: 'single',
            fasesList: [
                'Nuevo',
                'Pendiente',
                'Recaudo',
                'Ejecucion',
                'Finalizado'
            ], 
            departamentosList: departamentos,            
            municipiosList: [],
            municipioSelected:'',
            departamentoSelected:'',
            projectStatus:'',
            selectedProject: {},
            projectID:'',
            producerName:'',
            projectName:'', 
            projectCode:'',
            fields: [
                { key: 'projectName', label: 'Código - Proyecto' },
                { key: 'projectType', label: 'Tipo' },                
                { key: 'producerName', label: 'Productor' },
                { key: 'projectProgress', label: 'Progreso', thStyle:{ width: "25%"} }, 
                { key: 'projectStatus', label: 'Fase' , thStyle:{ width: "10%"} },
                { key: 'investmentRequired', label: 'Kg solicitados' },
                { key: 'endDate', label: 'Liquidacion' },
                { key: 'lastWeigthDate', label: 'Ult Pesaje' },
            ],
            projects: [{ }],
            axiosIns: axiosIns,
            userType: null,
        }
    },
    created(){     
        var userData = getUserData();
        this.userType = userData.userType;
        this.ProjectsToApprove();
    },
    methods:{
        ProjectsToApprove(){            
            axios.get('/Project/GetProjectsToApprove',  { params: { state: this.departamentoSelected, 
                    city: this.municipioSelected, producer: this.producerName, project: this.projectName, status: this.projectStatus, code:this.projectCode }})
             .then(response => {                
                this.projects = response.data;
                if(this.projects.length > 0){
                    this.projects.forEach(function(item, index){
                        if(item.investmentRequired != null && item.investmentCollected != null){
                            item.projectProgress = (parseFloat(item.investmentCollected) * 100 / parseFloat(item.investmentRequired)).toFixed(2);
                        } else {                      
                            item.projectProgress = 0;
                            item.investmentCollected = 0;
                        }                 
                    });
                }
            })
        },
        onDepartamentoSelect(event){
            this.departamentoSelected = event;        
            var departamento = this.departamentosList.find(x => x.departamento === event);
            this.municipiosList = departamento.ciudades;            
        },
        onMunicipioSelect(event){
            this.municipioSelected = event;               
        },
        onFaseSelect(event){
            this.projectStatus = event;
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        showChangeStatusProjectModal() {
            this.$refs['change-status-project-modal'].show()
        },
        approveProject(){
            axios.get('/Project/ApproveProject', { params: { projectID: this.selectedProject.projectID }})
            .then(response => {
                window.location.reload()
            });
        },  
        moveToExecuting(){
            axios.get('/Project/MoveToExecuting', { params: { projectID: this.selectedProject.projectID }})
            .then(response => {
                window.location.reload()
            });
        },
        moveToPending(){
            axios.get('/Project/MoveToPending', { params: { projectID: this.selectedProject.projectID }})
            .then(response => {
                window.location.reload()
            });
        },
        moveToCollect(){
            axios.get('/Project/MoveToCollect', { params: { projectID: this.selectedProject.projectID }})
            .then(response => {
                window.location.reload()
            });
        },
        moveToFinished(){
            axios.get('/Project/MoveToFinished', { params: { projectID: this.selectedProject.projectID }})
            .then(response => {
                window.location.reload()
            });
        },
        generateInvoicesByProject(){
            axios.get('/Project/GenerateInvoicesByProject', { params: { projectID: this.selectedProject.projectID }})
            .then(response => {
                window.location.reload()
            })
            .catch(error => {
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        blockInvestment(){
            axios.get('/Project/BlockInvestment', { params: { projectID: this.projectID }})
            .then(response => {
                window.location.reload()
            });
        },   
        getInvestmentsInvestorsDetails(){
            window.open(this.axiosIns.sostyApiUrl + 'Project/GetInvestmentsInvestorsDetails?projectID=' + this.selectedProject.projectID, '_blank');
        },   
        onRowSelected(items) {
            this.selectedProject = items[0];  
            this.projectID = this.selectedProject.projectID                                 
            if(this.selectedProject) this.showModal();
        },
        viewProjectPublicDetails() {
            let route = this.$router.resolve({path: 'public-project-details?projectCode=' + this.selectedProject.projectCode});
            window.open(route.href, '_blank');
        },
        viewProducerDetails(){
            let route = this.$router.resolve({path: 'edit-producer-profile?producerID=' + this.selectedProject.producerID});
            window.open(route.href, '_blank');
        },
        editProject() {
            this.$refs['my-modal'].hide()
            this.$router.push('create-edit-project?projectID=' + this.selectedProject.projectID)
        },
        followUpProject(){
            this.$refs['my-modal'].hide()
            this.$router.push('follow-up-project?projectID=' + this.selectedProject.projectID)
        },

        goToProjectsLiquidations(){            
            this.projectID = this.selectedProject.projectID
            let route = this.$router.resolve({path: 'project-liquidations?projectID=' + this.projectID});
            window.open(route.href, '_blank');
        },
    },
    directives: {
        Ripple,
    },
}
</script>